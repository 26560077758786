// Utils
import axios from 'axios';
import { reportError } from '@utils/sentry';

// Types
import type { ApiError } from './types';

// Create API instance
const api = axios.create({ baseURL: process.env.NEXT_PUBLIC_LEMON_TREE_URL });

// Set Auth token
export const setAuthTokenForApi = (token: string) => {
    //applying token
    api.defaults.headers.common['Authorization'] = token;
};

// Methods
export const apiGet = (path: string) => {
    return api.get(path);
};

export const apiPost = (path: string, data: object) => {
    return api.post(path, data);
};

export const apiPatch = (path: string, data: object) => {
    return api.patch(path, data);
};

export const apiDelete = (path: string, data: object) => {
    return api.patch(path, data);
};

// Error handler
export const handleRuntimeError = (
    err: ApiError,
    options: { debugMessage?: string; silent?: boolean; message?: string } = {},
) => {
    const { debugMessage, silent, message } = options;

    if (debugMessage) {
        console.warn(debugMessage, err);
    }

    if (silent) {
        return;
    }

    const apiError = (err as ApiError).response;

    if (apiError) {
        // Don't report API errors
        return;
    } else {
        console.warn(message, err?.message);
    }

    reportError(err, 'runtime');
};
