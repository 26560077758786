import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NAME } from '../constants';
import { apiPost, handleRuntimeError } from '@core/api';

// Types
import type { ApiError } from '@core/types';
import type { AppThunk } from '@core/redux/store';

interface State {
    loading: boolean;
    error: ApiError;
    succeeded: boolean;
}

const initialState: State = {
    loading: false,
    error: null,
    succeeded: false,
};

export const resetPasswordSlice = createSlice({
    name: `${NAME}-reset-password`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setError(state, action: PayloadAction<ApiError>) {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSucceeded(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                succeeded: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// Actions
export const { setLoading, setError, setSucceeded, reset } = resetPasswordSlice.actions;

// Thunks
export const resetPassword =
    (email: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            dispatch(setSucceeded(false));
            dispatch(setError(null));

            // Send Reset password request
            await apiPost('/reset-password', {
                data: { email },
            });

            dispatch(setSucceeded(true));
            dispatch(setLoading(false));
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to reset password' });
            dispatch(
                setError(err.response ? err : { response: { data: { message: err.message } } }),
            );
            dispatch(setLoading(false));
        }
    };

// Selectors
export const getLoading = (state): boolean => state[NAME]?.resetPasswordReducer?.loading;
export const getError = (state): ApiError => state[NAME]?.resetPasswordReducer?.error;
export const getSucceeded = (state): boolean => state[NAME]?.resetPasswordReducer?.succeeded;

export default resetPasswordSlice.reducer;
