import React from 'react';

// UI
import NavBar from '@ui/components/NavBar';
import Footer from '@ui/components/Footer';

// Types
export interface Props {
    children: any;
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
    return (
        <>
            <NavBar />

            <div className="mx-auto mt-6 max-w-screen-lg px-4 pb-24 sm:mt-12 md:px-8">
                {children}

                <Footer />
            </div>
        </>
    );
};

export default DefaultLayout;
