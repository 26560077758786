import { NAME } from '@app/marketing/constants';

// Utils
import { cn } from '@utils/cn';
import imgix from '@core/imgix';

// Hooks
import { useTranslation } from 'next-i18next';

// Types
interface Props {
    labelKey?: string;
    className?: string;
    labelClassName?: string;
    staticSize?: boolean;
}

const PerspectiveLogoNeon = ({ labelKey, className, labelClassName, staticSize }: Props) => {
    const { t } = useTranslation(NAME);

    const wrapperClassName = cn('bg-cover bg-center bg-no-repeat', className, {
        'size-32 sm:size-40 lg:size-60': !staticSize,
    });

    const labelWrapperClassName = cn(
        'absolute z-10 flex items-center justify-center font-semibold rounded-full bg-black text-center text-white m-auto',
        labelClassName,
        {
            'text-xs sm:text-sm lg:text-xl inset-6 sm:inset-8 lg:inset-10': !staticSize,
        },
    );

    return (
        <div
            className={wrapperClassName}
            style={{ backgroundImage: `url(${imgix.bundles.perspectiveNeon})` }}
        >
            {!!labelKey && <span className={labelWrapperClassName}>{t(labelKey)}</span>}
        </div>
    );
};

PerspectiveLogoNeon.displayName = 'PerspectiveLogoNeon';

export default PerspectiveLogoNeon;
