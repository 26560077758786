// Utils
import Head from 'next/head';
import Script from 'next/script';
import ErrorBoundary from '../modules/core/errors/ErrorBoundary';
import { appWithTranslation } from 'next-i18next';
import { SpeedInsights } from '@vercel/speed-insights/next';

// Hooks
import { useInitializeCurrency } from '@app/account/hooks/useInitializeCurrency';

// UI
import Banner from '../modules/app/banner/components/Banner';
import '../styles/globals.css';
import 'flagpack/dist/flagpack.css';

// Redux
import { wrapper } from '@core/redux/store';

// Types
import type { AppProps } from 'next/app';

function App({ Component, pageProps }: AppProps) {
    useInitializeCurrency();

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
            </Head>

            {process.env.NODE_ENV === 'production' && (
                <Script id="partnerstack" strategy="afterInteractive">
                    {`(function() {var gs = document.createElement('script');gs.src = 'https://js.partnerstack.com/v1/';gs.type = 'text/javascript';gs.async = 'true';gs.onload = gs.onreadystatechange = function() {var rs = this.readyState;if (rs && rs != 'complete' && rs != 'loaded') return;try {growsumo._initialize('pk_Ox90vEM82ly2xQ4Xsw35ThcuiCxggq03'); if (typeof(growsumoInit) === 'function') {growsumoInit();}} catch (e) {}};var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(gs, s);})();`}
                </Script>
            )}

            <ErrorBoundary>
                <Banner />
                <Component {...pageProps} />
            </ErrorBoundary>

            <SpeedInsights sampleRate={0.01} />
        </>
    );
}

export default wrapper.withRedux(appWithTranslation(App));
