import { combineReducers } from 'redux';

import signupReducer from './signup';
import loginReducer from './login';
import couponReducer from './coupon';
import referralReducer from './referral';
import resetPasswordReducer from './resetPassword';
import userReducer from './user';

export default combineReducers({
    signupReducer,
    loginReducer,
    couponReducer,
    referralReducer,
    resetPasswordReducer,
    userReducer,
});
