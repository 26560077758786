// Utils
import imgix from '@core/imgix';

// UI
import PerspectiveLogoNeon from '@ui/components/PerspectiveLogoNeon';

// Types
import { BannerData, Benefit } from '@app/marketing/types';
import { ReactElement } from 'react';

export const NAME = 'marketing';

export const STARTER_BUSINESS_BUNDLE_BENEFITS: Benefit[] = [
    {
        icon: 'key',
        bgColor: '#026E81',
        i18nKey: 'starter-business-benefit-0',
    },
    {
        icon: 'key',
        bgColor: '#026E81',
        i18nKey: 'starter-business-benefit-1',
    },
    {
        icon: 'play',
        bgColor: '#00ABBD',
        i18nKey: 'starter-business-benefit-2',
    },
    {
        icon: 'cash',
        bgColor: '#A1C7E0',
        i18nKey: 'starter-business-benefit-3',
    },
    {
        icon: 'academy',
        bgColor: '#FF9933',
        i18nKey: 'starter-business-benefit-4',
    },
    {
        icon: 'facebook',
        bgColor: '#0099DD',
        i18nKey: 'starter-business-benefit-5',
    },
];

export const STARTER_ADVANCED_BUNDLE_BENEFITS: Benefit[] = [
    {
        icon: 'key',
        bgColor: '#026E81',
        i18nKey: 'starter-advanced-benefit-0',
    },
    {
        icon: 'key',
        bgColor: '#026E81',
        i18nKey: 'starter-advanced-benefit-1',
    },
    {
        icon: 'play',
        bgColor: '#00ABBD',
        i18nKey: 'starter-advanced-benefit-2',
    },
    {
        icon: 'cash',
        bgColor: '#A1C7E0',
        i18nKey: 'starter-advanced-benefit-3',
    },
    {
        icon: 'academy',
        bgColor: '#FF9933',
        i18nKey: 'starter-advanced-benefit-4',
    },
    {
        icon: 'facebook',
        bgColor: '#0099DD',
        i18nKey: 'starter-advanced-benefit-5',
    },
];

export const RECRUITING_VSL_BUNDLE_BENEFITS: Benefit[] = [
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'recruiting-vsl-benefit-0',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'recruiting-vsl-benefit-1',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'recruiting-vsl-benefit-2',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'recruiting-vsl-benefit-3',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'recruiting-vsl-benefit-4',
    },
];

export const VSL_INSTANT_BUNDLE_BENEFITS: Benefit[] = [
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'vsl-instant-benefit-0',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'vsl-instant-benefit-1',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'vsl-instant-benefit-2',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'vsl-instant-benefit-3',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'vsl-instant-benefit-4',
    },
];

export const VSL_FUNNEL_MONTH_BUNDLE_BENEFITS: Benefit[] = [
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'funnel-month-vsl-benefit-0',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'funnel-month-vsl-benefit-1',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'funnel-month-vsl-benefit-2',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'funnel-month-vsl-benefit-3',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'funnel-month-vsl-benefit-4',
    },
];

export const JOURNEYS_BUSINESS_BUNDLE_BENEFITS: Benefit[] = [
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-business-benefit-0',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-business-benefit-1',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-business-benefit-2',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-business-benefit-3',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-business-benefit-4',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-business-benefit-5',
    },
];

export const JOURNEYS_ADVANCED_BUNDLE_BENEFITS: Benefit[] = [
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-advanced-benefit-0',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-advanced-benefit-1',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-advanced-benefit-2',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-advanced-benefit-3',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-advanced-benefit-4',
    },
    {
        icon: 'check',
        bgColor: '#3C82F6',
        i18nKey: 'journeys-advanced-benefit-5',
    },
];

export const STARTER_BUSINESS_BANNER_DATA: BannerData = {
    bannerSrc: imgix.bundles.starterBusiness,
    bannerSize: { width: 960, height: 627 },
    bannerStyle: 'mt-36 sm:mt-36 lg:mt-40 px-4 sm:px-6 lg:px-20',
    renderBannerLogo: (): ReactElement => (
        <PerspectiveLogoNeon
            className="absolute -top-14 left-4 z-10 sm:-top-16 sm:left-8 lg:-top-24 lg:left-14"
            labelKey="bundles-banner-label"
        />
    ),
};

export const STARTER_ADVANCED_BANNER_DATA: BannerData = {
    bannerSrc: imgix.bundles.starterAdvanced,
    bannerSize: { width: 960, height: 567 },
    bannerStyle: 'mt-36 sm:mt-36 lg:mt-40 px-4 sm:px-6 lg:px-20',
    renderBannerLogo: (): ReactElement => (
        <PerspectiveLogoNeon
            className="absolute -top-14 left-4 z-10 sm:-top-20 sm:left-12 lg:-top-24 lg:left-14"
            labelKey="bundles-banner-label"
        />
    ),
};

export const RECRUITING_VSL_BANNER_DATA: BannerData = {
    bannerSrc: imgix.bundles.recruitingVSL,
    bannerSize: { width: 960, height: 625 },
};

export const VSL_INSTANT_BANNER_DATA: BannerData = {
    bannerSrc: imgix.bundles.instantVSL,
    bannerSize: { width: 960, height: 625 },
};

export const VSL_FUNNEL_MONTH_BANNER_DATA: BannerData = {
    bannerSrc: imgix.bundles.funnelMonthVSL,
    bannerSize: { width: 960, height: 625 },
};

export const JOURNEYS_BUSINESS_BANNER_DATA: BannerData = {
    bannerSrc: imgix.bundles.journeysBusinessBundle,
    bannerSize: { width: 960, height: 625 },
};

export const JOURNEYS_ADVANCED_BANNER_DATA: BannerData = {
    bannerSrc: imgix.bundles.journeysAdvancedBundle,
    bannerSize: { width: 960, height: 580 },
};

// Journeys Bundles Plan Ids
export const JOURNEYS_BUSINESS_PLAN_IDS = [
    'ps-business-12-month',
    'ps-business-USD-12-month',
    'ps-business-GBP-12-month',
];
export const JOURNEYS_ADVANCED_PLAN_IDS = [
    'ps-agency-12-month',
    'ps-agency-USD-12-month',
    'ps-agency-GBP-12-month',
];

// Plan Ids
export const BUNDLE_PLAN_IDS = [
    'ps-custom-12-month-crashcoursestarter',
    'ps-business-12-month-crashcourse',
    'ps-agency-12-month-crashcourse',
    'ps-business-1-month-recruiting-agency',
    'ps-business-1-month-instant-recruiting',
    // USD Plans
    'ps-business-USD-12-month-crashcourse',
    'ps-agency-USD-12-month-crashcourse',
    'ps-business-USD-1-month-recruiting-agency',
    'ps-business-USD-1-month-instant-recruiting',
    // GBP Plans
    'ps-business-GBP-12-month-crashcourse',
    'ps-agency-GBP-12-month-crashcourse',
    'ps-business-GBP-1-month-recruiting-agency',
    'ps-business-GBP-1-month-instant-recruiting',
];

export const JOURNEYS_BUNDLES_PLAN_IDS = [
    ...JOURNEYS_BUSINESS_PLAN_IDS,
    ...JOURNEYS_ADVANCED_PLAN_IDS,
];

export const REGULAR_PLAN_IDS = [
    'ps-business-1-month',
    'ps-business-12-month',
    'ps-pro-1-month',
    'ps-pro-12-month',
    'ps-agency-1-month',
    'ps-agency-12-month',
    // USD plans
    'ps-business-USD-1-month',
    'ps-business-USD-12-month',
    'ps-pro-USD-1-month',
    'ps-pro-USD-12-month',
    'ps-agency-USD-1-month',
    'ps-agency-USD-12-month',
    // GBP plans
    'ps-business-GBP-1-month',
    'ps-business-GBP-12-month',
    'ps-pro-GBP-1-month',
    'ps-pro-GBP-12-month',
    'ps-agency-GBP-1-month',
    'ps-agency-GBP-12-month',
];

export const CRASHCOURSE_BUSINESS_PLAN_IDS = [
    'ps-business-12-month-crashcourse',
    'ps-business-1-month-recruiting-agency',
    'ps-business-1-month-instant-recruiting',
    // USD Plans
    'ps-business-USD-12-month-crashcourse',
    'ps-business-USD-1-month-recruiting-agency',
    'ps-business-USD-1-month-instant-recruiting',
    // GBP Plans
    'ps-business-GBP-12-month-crashcourse',
    'ps-business-GBP-1-month-recruiting-agency',
    'ps-business-GBP-1-month-instant-recruiting',
];

export const AGENCY_PLAN_IDS = [
    'ps-agency-1-month',
    'ps-agency-12-month',
    'ps-agency-12-month-crashcourse',
    // USD Plans
    'ps-agency-USD-1-month',
    'ps-agency-USD-12-month',
    'ps-agency-USD-12-month-crashcourse',
    // GBP Plans
    'ps-agency-GBP-1-month',
    'ps-agency-GBP-12-month',
    'ps-agency-GBP-12-month-crashcourse',
];

export const INDIVIDUAL_PLAN_IDS = [
    'ps-individual-3-month',
    'ps-individual-6-month',
    'ps-individual-12-month',
    // USD Plans
    'ps-individual-USD-3-month',
    'ps-individual-USD-6-month',
    'ps-individual-USD-12-month',
    // GBP Plans
    'ps-individual-GBP-3-month',
    'ps-individual-GBP-6-month',
    'ps-individual-GBP-12-month',
];
