import React from 'react';

// Types
import { TextAlign } from '@ui/types';

export interface Props {
    children: React.ReactNode;
    align?: TextAlign;
    className?: string;
}

const Headline: React.FC<Props> = ({ children, align, className }) => {
    return (
        <h1
            className={`max-w-screen-md px-4 text-2xl font-bold text-gray-800 sm:whitespace-pre-line md:text-4xl ${
                align === 'center' ? 'mx-auto text-center' : 'text-left'
            } ${className || ''}`}
            data-cy="headline"
        >
            {children}
        </h1>
    );
};

Headline.defaultProps = { align: 'center' };

export default Headline;
