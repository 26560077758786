// Utils
import { setUser } from '@sentry/nextjs';
import { infoLog } from '@utils/debugLogs';

// Types
import type { TrackingObject } from '@core/types';

// Types
declare global {
    interface Window {
        analytics: any;
        growsumo: any;
    }
}

export interface UserData {
    id: string;
    email: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    language?: string;
}

interface IdentifyUserConfig {
    segment: any;
}

export const identifyUser = (user: UserData, config?: IdentifyUserConfig) => {
    identifySegmentUser(user, config?.segment);
    identifySentryUser(user);
};

// === Segment ======

export const identifySegmentUser = (user: UserData, config: object) => {
    if (window.analytics) {
        window.analytics.identify(user.id, user, {
            ...config,
            Intercom: { hideDefaultLauncher: false },
        });
    }
};

const trackSegment = (eventName: string, trackingObj: TrackingObject) => {
    if (window?.analytics) {
        window.analytics.track(eventName, trackingObj, {
            All: true,
            Intercom: false,
        });
    }
};

export const track = (eventName: string, trackingObj: TrackingObject) => {
    if (
        process.env.NODE_ENV !== 'production' ||
        process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
    ) {
        infoLog(`Track: "${eventName}" with ${JSON.stringify(trackingObj)}`);

        return;
    }

    trackSegment(eventName, trackingObj);
};

// === Sentry ======

export const identifySentryUser = (user: UserData) => {
    if (!user) {
        if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
            infoLog('Sentry: No user found to identify');
        }

        return;
    }

    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
        setUser({
            id: user.id,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email,
        });
    } else {
        infoLog('Sentry: Not identifying user in non-production environment');
    }
};
