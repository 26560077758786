import { NAME } from '../constants';

// Utils
import { createSlice } from '@reduxjs/toolkit';
import { apiPost, handleRuntimeError } from '@core/api';

// Types
import type { UserData } from '@core/tracking';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SubscriptionResource } from '../types';

interface State {
    user: UserData;
}

const initialState: State = {
    user: undefined,
};

export const userSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserData>) {
            return {
                ...state,
                user: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setUser, reset } = userSlice.actions;

// === Thunks ======

export const fetchSubscription = async (subscriptionId: string): Promise<SubscriptionResource> => {
    try {
        // Retrieve subscription
        const subscriptionRes = await apiPost('/retrieve-subscription', {
            data: { subscriptionId },
        });

        return subscriptionRes?.data.data;
    } catch (err) {
        handleRuntimeError(err, { message: 'Failed to fetch subscription' });
    }
};

// === Selectors ======

export const getUser = (state) => state[NAME]?.userReducer?.user;

export default userSlice.reducer;
