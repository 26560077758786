import React from 'react';
import { useTranslation } from 'next-i18next';

// UI
import LanguageSwitch from '@ui/components/LanguageSwitch';

// Types
export interface Props {}

const Footer: React.FC<Props> = ({}) => {
    const { t } = useTranslation('common');

    return (
        <div
            className="mt-20 w-full items-center justify-center text-center text-sm text-gray-400 sm:flex sm:px-6 sm:text-left"
            data-cy="footer"
        >
            <div>
                © Perspective Software GmbH ·{' '}
                <a
                    href={t('tos-link-href')}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500"
                >
                    {t('tos-link')}
                </a>{' '}
                ·{' '}
                <a
                    href={t('privacy-link-href')}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500"
                >
                    {t('privacy-link')}
                </a>{' '}
                ·{' '}
                <a
                    href={t('imprint-link-href')}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-500"
                >
                    {t('imprint-link')}
                </a>
            </div>

            <div className="relative mt-6 flex h-[46px] w-full justify-center pl-0 sm:mt-0 sm:block sm:w-[140px] sm:pl-6">
                <LanguageSwitch />
            </div>
        </div>
    );
};

export default Footer;
