import React from 'react';

// UI
import { cn } from '@utils/cn';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

// Types
export interface Props {
    content: string;
    index: number;
    done?: boolean;
    active?: boolean;
}

const NavItem: React.FC<Props> = ({ content, done, active, index }) => {
    const wrapperClass = cn('text-sm cursor-default', {
        ['text-green-500']: done,
        ['text-blue-500']: active,
        ['text-gray-400']: !active && !done,
    });

    return (
        <div className={wrapperClass}>
            <div className="flex items-center">
                {done ? <CheckCircleIcon className="mr-1 h-4 w-4" /> : `${index}. `} {content}
            </div>
        </div>
    );
};

export default NavItem;
