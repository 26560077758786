import { NAME } from '../constants';

// Utils
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types
import type { BannerData } from '../types';
import type { AppState, AppThunk } from '@core/redux/store';

interface State extends BannerData {}

const initialState: State = {
    type: null,
    options: {
        content: '',
    },
};

export const bannerSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setBanner(state, action: PayloadAction<BannerData>) {
            return {
                ...state,
                ...action.payload,
            };
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const { setBanner, reset } = bannerSlice.actions;

// === Thunks ======

export const showBanner =
    (type: BannerData['type'], options: BannerData['options']): AppThunk =>
    (dispatch) => {
        return dispatch(
            setBanner({
                type,
                options,
            }),
        );
    };

export const hideBanner = () => (dispatch) => {
    return dispatch(reset());
};

// === Selectors ======

export const getBanner = (state: AppState) => state[NAME]?.bannerReducer;

export default bannerSlice.reducer;
