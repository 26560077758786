import React from 'react';

// Types
import { TextAlign } from '@ui/types';

export interface Props {
    children: React.ReactNode;
    align?: TextAlign;
}

const Subtitle: React.FC<Props> = ({ children, align = 'center' }) => {
    return (
        <div
            className={`mt-4 max-w-screen-lg px-4 text-base text-gray-500 sm:whitespace-pre-line ${
                align === 'center' ? 'mx-auto text-center' : 'text-left'
            }`}
            data-cy="subtitle"
        >
            {children}
        </div>
    );
};

export default Subtitle;
