import { NAME } from '../constants';

// Utils
import { apiPost, handleRuntimeError } from '@core/api';
import { i18n } from 'next-i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Redux
import { setBanner } from '@app/banner/models/banner';

interface State {
    couponIds?: string | string[];
    isCouponValid: boolean;
    trialEnd?: number;
    loading: boolean;
}

const initialState: State = {
    couponIds: null,
    isCouponValid: false,
    trialEnd: null,
    loading: false,
};

export const couponSlice = createSlice({
    name: `${NAME}-coupon`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setCouponIds(state, action: PayloadAction<string | string[]>) {
            return {
                ...state,
                couponIds: action.payload,
            };
        },
        setIsCouponValid(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isCouponValid: action.payload,
            };
        },
        setTrialEnd(state, action: PayloadAction<number>) {
            return {
                ...state,
                trialEnd: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// Actions
export const { setLoading, setCouponIds, setIsCouponValid, setTrialEnd, reset } =
    couponSlice.actions;

// Thunks
export const validateCoupon = (couponId: string) => async (dispatch) => {
    const t = i18n?.getFixedT(null, NAME);

    try {
        dispatch(setLoading(true));

        dispatch(setCouponIds(couponId));

        const res = await apiPost('/retrieve-coupon', {
            data: {
                couponId,
            },
        });

        const coupon = res?.data?.data?.attributes?.coupon;

        dispatch(setIsCouponValid(!!coupon));

        console.log(coupon);

        dispatch(
            setBanner({
                type: 'info',
                options: {
                    icon: 'success',
                    grayOut: true,
                    content: t('coupon-activated'),
                },
            }),
        );
    } catch (err) {
        handleRuntimeError(err, { message: 'Failed to validate coupon' });
        dispatch(setIsCouponValid(false));
        dispatch(
            setBanner({
                type: 'warning',
                options: {
                    icon: 'warning',
                    grayOut: true,
                    content: t('coupon-invalid'),
                },
            }),
        );
    } finally {
        dispatch(setLoading(false));
    }
};

// Selectors
export const getLoading = (state): boolean => state[NAME]?.couponReducer?.loading;
export const getCouponIds = (state): string | string[] => state[NAME]?.couponReducer?.couponIds;
export const getTrialEnd = (state): number => state[NAME]?.couponReducer?.trialEnd;
export const getIsCouponValid = (state): boolean => state[NAME]?.couponReducer?.isCouponValid;

export default couponSlice.reducer;
