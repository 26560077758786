import React from 'react';
import { useTranslation } from 'next-i18next';

// Types
import { Language, flagMap } from './index';

export interface Props {
    language: Language;
    onClick: (language: Language) => void;
}

const LanguageButton: React.FC<Props> = ({ language, onClick }) => {
    const { t } = useTranslation('common');

    const handleClick = () => onClick(language);

    return (
        <div
            className="mb-1 hidden cursor-pointer items-center rounded px-4 py-2 hover:bg-gray-100 group-hover:flex"
            onClick={handleClick}
        >
            {flagMap[language]}
            <span className="ml-2">{t(`language-${language}`)}</span>
        </div>
    );
};

export default LanguageButton;
