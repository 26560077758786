import { Component, ReactNode } from 'react';
import { withTranslation } from 'next-i18next';
import { reportError } from '@utils/sentry';

// UI
import TiltScreen from '@ui/components/TiltScreen';
import Headline from '@ui/components/Headline';
import Subtitle from '@ui/components/Subtitle';
import DefaultLayout from '@ui/layouts/Default.layout';

// Types
import { WithTranslation } from 'next-i18next';

export interface Props {
    children?: ReactNode;
}

export interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<WithTranslation & Props, State> {
    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        if (process.env.NODE_ENV === 'production') {
            reportError(error, 'boundary');
        }
    }

    handleTryAgain = () => {
        this.setState({ hasError: false });
    };

    render() {
        const { t, children } = this.props;

        if (this.state.hasError) {
            return (
                <DefaultLayout>
                    <div className="mx-auto flex max-w-screen-md flex-col space-y-12 md:flex-row md:space-x-12 md:space-y-0">
                        <TiltScreen title={'Oops!'} />

                        <div className="flex flex-1 flex-col items-center justify-center text-center md:items-start md:text-left">
                            <Headline align="left">{t('error-title')}</Headline>
                            <Subtitle align="left">{t('error-description')}</Subtitle>

                            <button
                                type="button"
                                className="ml-4 mt-6 rounded-lg bg-blue-500 px-6 py-3 text-white hover:bg-blue-600"
                                onClick={this.handleTryAgain}
                            >
                                {t('error-try-again')}
                            </button>
                        </div>
                    </div>
                </DefaultLayout>
            );
        }

        return children;
    }
}

export default withTranslation('common')(ErrorBoundary);
