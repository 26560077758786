import { NAME } from '../constants';

// Utils
import axios from 'axios';
import { i18n } from 'next-i18next';
import { handleRuntimeError } from '@core/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Redux
import { setBanner } from '@app/banner/models/banner';

// Types
import type { AppThunk } from '@core/redux/store';

interface State {
    partnerKey?: string;
    isPartnerValid: boolean;
    loading: boolean;
}

const initialState: State = {
    partnerKey: null,
    isPartnerValid: false,
    loading: false,
};

export const referralSlice = createSlice({
    name: `${NAME}-referral`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setPartnerKey(state, action: PayloadAction<string>) {
            return {
                ...state,
                partnerKey: action.payload,
            };
        },
        setIsPartnerValid(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isPartnerValid: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// Actions
export const { setLoading, setPartnerKey, setIsPartnerValid, reset } = referralSlice.actions;

// Thunks
export const validatePartnership =
    (partnerKey: string): AppThunk =>
    async (dispatch) => {
        const t = i18n?.getFixedT(null, NAME);
        try {
            dispatch(setLoading(true));

            dispatch(setPartnerKey(partnerKey));

            const isPartnerKeyValidRes = await axios.post(
                `${process.env.NEXT_PUBLIC_LEMON_TOOL_API_URL}/referral/partnership/validate`,
                {
                    data: { partnerKey },
                },
            );

            const isPartnerKeyValid = isPartnerKeyValidRes?.data?.data?.isValid;

            if (isPartnerKeyValid) {
                dispatch(setIsPartnerValid(isPartnerKeyValid));
                dispatch(
                    setBanner({
                        type: 'info',
                        options: {
                            icon: 'success',
                            grayOut: true,
                            content: t('referral-code-activated'),
                        },
                    }),
                );
            } else {
                throw new Error('Partnership not valid');
            }
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to validate partnership' });
            dispatch(setIsPartnerValid(false));
            dispatch(
                setBanner({
                    type: 'warning',
                    options: {
                        icon: 'warning',
                        grayOut: true,
                        content: t('referral-code-invalid'),
                    },
                }),
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

// Selectors
export const getLoading = (state): boolean => state[NAME]?.referralReducer?.loading;
export const getPartnerKey = (state): string => state[NAME]?.referralReducer?.partnerKey;
export const getIsPartnerValid = (state): boolean => state[NAME]?.referralReducer?.isPartnerValid;

export default referralSlice.reducer;
