// Utils
import { infoLog } from './debugLogs';
import { captureException, captureMessage, withScope } from '@sentry/nextjs';

// Types
import type { SeverityLevel } from '@sentry/nextjs';

export const reportError = (error: Error | string, source?: string) => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
        withScope((scope) => {
            if (source) {
                scope.setTag('source', source);
            }

            captureException(error);
        });
    } else {
        infoLog(`Report to Sentry (Source: ${source}):`, error);
    }
};

export const reportMessage = (message: string, source?: string, severityLevel?: SeverityLevel) => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
        withScope((scope) => {
            if (source) {
                scope.setTag('source', source);
            }

            captureMessage(message, severityLevel ?? 'warning');
        });
    } else {
        infoLog(`Report to Sentry as message (Source: ${source}):`, message);
    }
};
