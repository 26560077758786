/* CAUTION: Imgix does not support animated gifs (yet) */

export const getImgixUrl = (path: string) => {
    const cleanPath = path.replace(/^\/$/g, '');

    return `${process.env.NEXT_PUBLIC_IMGIX_CDN || '/'}${cleanPath}`;
};

export const imgix = {
    bundles: {
        journeysBusinessBundle: getImgixUrl(
            'assets/checkout/bundles/journeys-business-bundle-cover.png',
        ),
        journeysAdvancedBundle: getImgixUrl(
            'assets/checkout/bundles/journeys-advanced-bundle-cover.png',
        ),
        funnelMonthVSL: getImgixUrl('assets/checkout/bundles/funnel-month-vsl.png'),
        instantVSL: getImgixUrl('assets/checkout/bundles/instant-vsl.png'),
        recruitingVSL: getImgixUrl('assets/checkout/bundles/recruiting-vsl.png'),
        starterAdvanced: getImgixUrl('assets/checkout/bundles/starter-advanced.png'),
        starterBusiness: getImgixUrl('assets/checkout/bundles/starter-business.png'),
        perspectiveNeon: getImgixUrl('assets/checkout/bundles/perspective-neon.png'),
    },
    shared: {
        sharedVideoBg: getImgixUrl('assets/checkout/shared/shared-video-bg.png'),
    },
};

export default imgix;
