import React from 'react';

// UI
import Tilt from 'react-parallax-tilt';

// Types
export interface Props {
    title: string;
}

const TitleScreen: React.FC<Props> = ({ title }) => {
    return (
        <Tilt
            className="relative mx-auto h-52 w-80 transform-gpu rounded-xl border shadow transition-shadow hover:shadow-lg md:mx-0"
            style={{ transformStyle: 'preserve-3d' }}
        >
            <div
                className="absolute left-0 top-0 flex p-3"
                style={{ transform: 'translateZ(20px)' }}
            >
                <div className="h-2 w-2 rounded-full bg-red-500" />
                <div className="mx-1 h-2 w-2 rounded-full bg-yellow-500" />
                <div className="h-2 w-2 rounded-full bg-green-500" />
            </div>
            <div
                className="absolute left-0 top-0 flex h-full w-full animate-background items-center justify-center bg-gradient-to-r from-cyan-400 via-blue-500 to-orange-500 bg-[length:400%_auto] bg-clip-text text-6xl font-bold text-transparent"
                style={{ transform: 'translateZ(20px)' }}
            >
                {title}
            </div>
        </Tilt>
    );
};

export default TitleScreen;
