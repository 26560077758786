import { Action, AnyAction, combineReducers, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { Context, createWrapper, HYDRATE } from 'next-redux-wrapper';

// Modules
import * as accountConstants from '@app/account/constants';
import * as bannerConstants from '@app/banner/constants';
import * as pricingConstants from '@app/pricing/constants';
import * as marketingConstants from '@app/marketing/constants';

// Reducers
import accountReducer from '@app/account/models';
import bannerReducer from '@app/banner/models';
import pricingReducer from '@app/pricing/models';
import marketingReducer from '@app/marketing/models';

// Combine all reducers
const combinedReducer = combineReducers({
    [accountConstants.NAME]: accountReducer,
    [bannerConstants.NAME]: bannerReducer,
    [pricingConstants.NAME]: pricingReducer,
    [marketingConstants.NAME]: marketingReducer,
});

// Root reducer
const rootReducer = (state, action: AnyAction) => {
    if (action.type === HYDRATE) {
        return {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        };
    } else {
        return combinedReducer(state, action);
    }
};

// create a makeStore function
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV === 'development',
});

const makeStore = (context: Context) => store;

// export an assembled wrapper
export const wrapper = createWrapper<AppStore>(makeStore, {
    debug: process.env.NODE_ENV === 'development',
});

// Types
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
