// Utils
import { cn } from '@utils/cn';

// Hooks
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// UI
import { CheckBadgeIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

// Redux
import { getBanner } from '@app/banner/models/banner';

// Types
import type { FC } from 'react';

export interface Props {}

const iconMap = {
    success: CheckBadgeIcon,
    warning: ExclamationCircleIcon,
};

const Banner: FC<Props> = ({}) => {
    const [visible, setVisible] = useState(false);
    const [grayedOut, setGrayedOut] = useState(false);

    const banner = useSelector(getBanner);
    const { type, options } = banner;

    const IconComponent = options.icon && iconMap[options.icon];

    useEffect(() => {
        if (options.grayOut) {
            setTimeout(() => {
                setGrayedOut(true);
            }, 3000);
        }
    }, [options.grayOut]);

    useEffect(() => {
        if (type) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [banner, type]);

    const wrapperClasses = cn(
        'wrapper overflow-hidden transition-[height] duration-500 ease-in-out',
        {
            'h-0': !type,
            'h-11': !!type,
        },
    );

    const contentStyle = cn(
        'flex h-11 w-full items-center justify-center text-sm font-medium transition-all ease duration-[2000ms]',
        {
            'bg-blue-500 text-white': type === 'info' && !grayedOut,
            'bg-yellow-300 text-gray-700': type === 'warning' && !grayedOut,
            'bg-gray-50 text-gray-700': grayedOut,
        },
    );

    return (
        <div className={wrapperClasses}>
            {visible && (
                <div className={contentStyle}>
                    {options.icon ? <IconComponent className="mr-2.5 size-5" /> : null}
                    {options.content}
                </div>
            )}
        </div>
    );
};

export default Banner;
