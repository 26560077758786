import { NAME } from '../constants';

// Utils
import axios from 'axios';
import { handleRuntimeError } from '@core/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types
import type { ApiError } from '@core/types';
import type { AppThunk } from '@core/redux/store';
import type { FunnelDetails } from '@app/marketing/types';

interface State {
    loading: boolean;
    error: ApiError;
    funnelDetails: FunnelDetails;
}

const initialState: State = {
    loading: false,
    error: null,
    funnelDetails: {
        name: '',
        affiliateLink: '',
    },
};

export const sharedFunnelSlice = createSlice({
    name: `${NAME}-shared-funnel`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setError(state, action: PayloadAction<ApiError>) {
            return {
                ...state,
                error: action.payload,
            };
        },
        setFunnelDetails(state, action: PayloadAction<FunnelDetails>) {
            return {
                ...state,
                funnelDetails: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// Actions
export const { setLoading, setError, setFunnelDetails, reset } = sharedFunnelSlice.actions;

// Thunks
export const fetchFunnelDetails =
    (funnelId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            dispatch(setError(null));

            if (funnelId) {
                const response = await axios.get(
                    `${process.env.NEXT_PUBLIC_LEMON_TOOL_API_URL}/campaigns/${funnelId}/shared-info`,
                );

                const funnelDetails: FunnelDetails = response.data.data;

                dispatch(setFunnelDetails(funnelDetails));

                dispatch(setLoading(false));
            }
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to fetch funnel details' });
            dispatch(setError(err));
            dispatch(setLoading(false));
        }
    };

// Selectors
export const getLoading = (state): ApiError => state[NAME]?.sharedFunnelReducer?.loading;
export const getError = (state): ApiError => state[NAME]?.sharedFunnelReducer?.error;
export const getFunnelDetails = (state): FunnelDetails =>
    state[NAME]?.sharedFunnelReducer?.funnelDetails;

export default sharedFunnelSlice.reducer;
