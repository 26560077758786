// Hooks
import { useTranslation } from 'next-i18next';

// UI
import Image from 'next/image';
import NavItem from '@ui/components/NavBar/NavItem';

// Types
import type { NavStep } from '@ui/components/NavBar/types';

export interface Props {
    activeStep?: NavStep;
}

const NavBar = ({ activeStep }: Props) => {
    const { t } = useTranslation('common');

    return (
        <nav className="flex h-[74px] w-screen items-center justify-between" data-cy="nav">
            <div className="z-10 p-4 text-[0px]">
                <a href={t('homepage-link-href')}>
                    <Image src="/logo.png" width="32" height="32" alt="Logo" />
                </a>
            </div>

            {activeStep && (
                <div className="absolute hidden w-full justify-center lg:flex">
                    <div className="flex w-96 justify-between">
                        <NavItem
                            content={t('register')}
                            index={1}
                            done={activeStep === 'confirm'}
                            active={activeStep === 'register'}
                        />
                        <NavItem
                            content={t('confirm')}
                            index={2}
                            active={activeStep === 'confirm'}
                        />
                        <NavItem content={t('build-funnel')} index={3} />
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavBar;
